// @flow strict
import React from 'react';
import { withPrefix, Link } from 'gatsby';
import styles from './Author.module.scss';
import { useSiteMetadata } from '../../../hooks';

const Author = () => {
  const { author, copyright } = useSiteMetadata();

  return (
    <div className={styles['author']}>
      <Link to="/">
        <img
          src={withPrefix(author.photo)}
          className={styles['author__photo']}
          width="75"
          height="75"
          alt={author.name}
        />
      </Link>
      <h3 className={styles['author__title']}>
        <Link className={styles['author__title-link']} to="/">
          {author.name}
        </Link>
      </h3>
      <p className={styles['author__subtitle']}>{author.bio}</p>
      <div className={styles['author__copyright']}>{copyright}</div>
    </div>
  );
};

export default Author;
